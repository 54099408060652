import React from 'react'
import Locations from '../components/Locations/locations'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const LocationsPage = () => (
    <Layout>
         <Helmet
          title="VIP CARS - Rent a Car | Gdje iznajmiti luksuzno vozilo (auto)?"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | Preuzmite Vaše omiljeno luksuzno vozilo (auto) gdje god želite na području BiH, Srbije, Hrvatske i Crne Gore.  T:+387 62 520 556.' },
          ]} >
        </Helmet>
        <div  id="locations">
    <div className="header-back"></div>
    <Header/>
   
    <section className="section locations">
         <div className="container">

            <Locations/>
        </div>
   </section>
   <Footer/>
   </div>
   </Layout>
)

export default LocationsPage
