import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {you_can, find_us, easily, address, phone, email, locations_subtitle} from '../strings'

const Locations = ({ siteTitle }) => (
    <div>
     <div className="columns first-section">
                <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-8 map-box" > 
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3422.1114772134956!2d18.319142257453674!3d43.84253550017296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758ca34241a7ba1%3A0xb50a3b21ae36bd04!2sIsmeta+Alajbegovi%C4%87a+%C5%A0erbe+1a%2C+Sarajevo+71000!5e0!3m2!1shr!2sba!4v1559115791532!5m2!1shr!2sba"></iframe>
                </div>
                <div animateIn='zoomIn' animateOut='zoomOut' className="column is-4 content">
                    <h2 className="title-big">{you_can} <br /><span>{find_us}</span>{/*<br/>{easily}*/}</h2>
                    <h6 className="subtitle">{locations_subtitle}</h6>
                </div>
               
        </div> 
        <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="divider"></div>
        <div className="columns seciond-section contact-info">
        <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="column is-4">
                <span className="icon"><FontAwesomeIcon icon="map"/></span>
                <h4 className="title">{address}</h4>
                <h2 className="answer">Ismeta Alajbegovića Šerbe 1A, 71000</h2>
            </div>

             <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="column is-4">
                <span className="icon"><FontAwesomeIcon icon="phone"/></span>
                <h4 className="title">{phone}</h4>
                <h2 className="answer">033 638 467<br />033 762 470<br />033 465 123 <br /> 062/520 556</h2>
            </div>

             <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="column is-4">
                <span className="icon"><FontAwesomeIcon icon="envelope-open"/></span>
                <h4 className="title">{email}</h4>
                <h2 className="answer">info@vipcars.ba</h2>
            </div>
        </div>
    </div>
)


export default Locations
